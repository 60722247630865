:root {

/* Colors */
--alabaster: #f8f8f8;
--athens-gray: #f0f2f5;
--dark-half: #494949;
--dove-gray: #666;
--purple: #d897eb;
--table-border-color: #f4f4f4;
--white: #FFF;

--yellow-4: #ffdd76;
--yellow-6: #ffbf00;
--purple-6: #7265e6;
--blue-1: #ecf6fd;
--blue-2: #d2eafb;
--blue-3: #add8f7;
--blue-4: #7ec2f3;
--blue-5: #49a9ee;
--blue-6: #108ee9;
--blue-7: #0e77ca;
--red-5: #f46e65;
--red-6: #f04134;
--red-7: #d73435;
--green-5: #3dbd7d;
--green-6: #00a854;
--green-7: #00924c;
--grey-2: #f7f7f7;
--grey-3: #f5f5f5;
--grey-4: #e9e9e9;
--grey-5: #d9d9d9;
--grey-6: #bfbfbf;
--grey-7: #919191;
--grey-10: #222222;

/* Shadows */
--shadow-1: .4rem .4rem 2rem 0 rgba(0, 0, 0, .01);
--shadow-2: .4rem .4rem 4rem 0 rgba(0, 0, 0, .05);
--shadow-3: 0 1em 1.5em -1.2em hsla(0,0%,78%,.3), 0 1em 1.5em -0.8em hsla(0,0%,78%,.2), 0 1em 1.5em -0.5em hsla(0,0%,78%,.1);

/* Transitions */
--transition-ease-in: all 0.15s ease-out;
--transition-ease-out: all 0.15s ease-out;
--transition-ease-in-out: all 0.15s ease-in-out;
--ease-in: ease-in;

/* Fonts */
--sans-serif: 'Open Sans', sans-serif;
}
