@import '~antd/dist/antd.css';
@import './variables.css';

html {
  box-sizing: border-box;
  font-size: 62.5%;
  overflow: hidden;
}

body {
  display: block;
  font-family: var(--sans-serif);
  font-size: 1.4rem;
  overflow-x: hidden;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-family: var(--sans-serif);
  font-weight: 400;
}

b,
strong {
  font-weight: 600;
}

b.stronger,
strong.stronger {
  font-weight: 700;
}

.pace {
  pointer-events: none;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  transform: translate3d(0, -50px, 0);
  transition: transform 0.5s ease-out;
  z-index: 100;
}

.pace.pace-active {
  transform: translate3d(0, 0, 0);
}

.pace .pace-progress {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 0.3rem;
  background: var(--yellow-6);
  pointer-events: none;
}
